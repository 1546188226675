import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-gallery',
  templateUrl: './web-gallery.component.html',
  styleUrls: ['./web-gallery.component.css']
})
export class WebGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
