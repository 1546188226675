import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserDto } from './DtoClasses/UserDto';
import { RequestResult } from './DtoClasses/RequestResult';
import { Settings } from './Global';
import { BaseService } from './BaseService';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  private isUserLogged: boolean = false;

  public LoggedUser: UserDto = null;

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }
  
  public IsLogged(): boolean {
    return this.isUserLogged;
  }

  public Login(userName: string, password: string, afterLogin: (result: boolean) => void) {
    let formData = new FormData();
    formData.append('Login', userName);
    formData.append('Password', password);
    this.Post<UserDto>(Settings.BaseApiUrl + "Login.php", formData, (userDto) => {
      if (userDto != null) {
        this.isUserLogged = true;
        this.LoggedUser = userDto;
      }
      afterLogin(userDto != null);
    });
  }

  public Logout() {
    this.isUserLogged = false;
    this.LoggedUser = null;
    this.httpClient.get(Settings.BaseApiUrl + "Logout.php").toPromise();
  }

  public ChangePassword(oldPassword: string, newPassword: string, confirmNewPassword: string, result: (data: RequestResult) => void) {
    let formData = new FormData();
    formData.append('Id', this.LoggedUser.Id.toString());
    formData.append('OldPassword', oldPassword);
    formData.append('NewPassword', newPassword);
    formData.append('ConfirmNewPassword', confirmNewPassword);
    this.Post<RequestResult>(Settings.BaseApiUrl + "ChangePassword.php", formData, result);
  }

  public GetUsers(result: (data: Array<UserDto>) => void) {
    this.Get<Array<UserDto>>(Settings.BaseApiUrl + "GetUsers.php", null, result);
  }

  public GetUserById(userId: number, result: (data: UserDto) => void) {
    let params = new HttpParams().set("Id", userId.toString());
    this.Get<UserDto>(Settings.BaseApiUrl + "GetUserById.php", params, result);
  }

  public UpdateUser(user: UserDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "UpdateUser.php", user, result);
  }

  public InsertUser(user: UserDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "InsertUser.php", user, result);
  }

  public DeleteUser(userId: number, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("UserId", userId.toString())
    this.Get<RequestResult>(Settings.BaseApiUrl + "DeleteUser.php", params, result);
  }
}
