import { Component, OnInit } from '@angular/core';
import { WebMenuConfig, WebMenuDisplayMode } from '../web-menu/web-menu.component';

@Component({
  selector: 'web-main',
  templateUrl: './web-main.component.html',
  styleUrls: ['./web-main.component.css']
})
export class WebMainComponent implements OnInit {
  private mainMenuConfig: WebMenuConfig = {
    DisplayMode: WebMenuDisplayMode.Horizontally, 
    ShowMenuName: false,
    NameLabelClasses: "test-menu-class",
    MainMenuClasses: "navbar-nav ml-auto",
    MainMenuItemContainerClasses: "nav-item",
    MainMenuItemClasses: "nav-link js-scroll-trigger",
    DropDownMenuClasses: "",
    DropDownMenuItemContainerClasses: "",
    DropDownMenuItemClasses: ""
  };

  constructor() { }

  ngOnInit() {
  }

}
