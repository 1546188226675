import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { GalleryImageDto } from './GalleryDataClasses';
import { GalleryService } from '../gallery.service';
import { GalleryFolderItem } from './GalleryFolderItem';
import { GalleryFolderInfo } from './GalleryFolderInfo';
import { CmsGalleryCreateElementComponent, GalleryItemType } from '../cms-gallery-create-element/cms-gallery-create-element.component';
import { CmsDialogComponent } from '../cms-dialog/cms-dialog.component';
import { Subscription } from 'rxjs/internal/Subscription';

declare var $: any;

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  private componentUrl: string;
  private actualFolderPath: string;
  private readonly foldersInfo: Array<GalleryFolderInfo> = new Array<GalleryFolderInfo>();
  private imageItems: Array<GalleryImageDto>;
  private folderItems: Array<GalleryFolderItem>;
  private showFullScreenMode: boolean = false;
  private imageIsLoaded: boolean = false;
  private fullScreenImgUrl: string;
  private actualFullScreenImageIndex: number = 0;
  private animationTimer: any;
  private selectedItem = null;
  private eventSubscribe: Subscription = null;

  @ViewChild(CmsDialogComponent) deleteDialog;
  @ViewChild(CmsGalleryCreateElementComponent) addNewGalleryItemDialog;

  @Input() editMode = false;

  constructor(
    private galleryService: GalleryService,
    private route: ActivatedRoute,
    private router: Router) {

    this.componentUrl = (this.router.url).split("?")[0];
    //TODO: Fix problem with GET caching in IE11
    this.eventSubscribe = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //get the folder path from url
        this.route
          .queryParams
          .subscribe(params => {
            this.actualFolderPath = params['FolderPath'] || "";
          });

        this.foldersInfo.splice(0, this.foldersInfo.length);

        if (this.actualFolderPath != "") {
          var folderNames = ("/" + this.actualFolderPath).split("/");
          for (var i = 0; i < folderNames.length - 1; i++) {
            var folderInfo = new GalleryFolderInfo();
            folderInfo.FolderName = folderNames[i] == "" ? "Galéria" : folderNames[i];
            folderInfo.FolderPath = "";
            this.foldersInfo.push(folderInfo);

            //generate the folder path
            for (var j = 1; j <= i; j++) {
              if (j != 1) {
                folderInfo.FolderPath += "/";
              }
              folderInfo.FolderPath += folderNames[j];
            }
          }
        }
        this.LoadGalleryItems();
      }
    });
  }

  ngOnInit() {
    this.animationTimer = setTimeout(() => { this.AnimateFolder(); }, 5000);
  }

  ngOnDestroy() {
    if (this.animationTimer) {
      clearTimeout(this.animationTimer);
    }

    this.eventSubscribe.unsubscribe();
  }

  private LoadGalleryItems() {
    this.galleryService.getGalleryItemsDto(this.actualFolderPath, (data) => {
      this.imageItems = data.Images;
      this.folderItems = new Array<GalleryFolderItem>();

      for (var i = 0; i < data.Folders.length; i++) {
        var folderItem = new GalleryFolderItem(data.Folders[i]);
        folderItem.FolderIndex = i;
        this.folderItems.push(folderItem);
      }
    });
  }

  private AnimateFolder() {
    var galleryClass = this;
    $(".folder img").fadeOut("slow", function () {
      let folderIndex = $(this).attr("folderIndex") as number;
      let folderItem = galleryClass.folderItems[folderIndex];

      if (folderItem != undefined) {
        folderItem.AnimationImageIndex++;
        if (folderItem.AnimationImageIndex >= folderItem.ImagesUrls.length) {
          folderItem.AnimationImageIndex = 0;
        }
      }

      $(".folder img").fadeIn("slow");
    });
    this.animationTimer = setTimeout(() => { this.AnimateFolder(); }, 7000);
  }

  private ShowFullScreen(imgUrl: string): void {
    this.imageIsLoaded = false;
    for (let i = 0; i < this.imageItems.length; i++) {
      if (this.imageItems[i].ImageUrl == imgUrl) {
        this.actualFullScreenImageIndex = i;
        break;
      }
    }

    this.showFullScreenMode = true;
    this.fullScreenImgUrl = imgUrl;
  }

  private ThubnailLoadedEvent(event: any): void {
    event.target.className = event.target.width < event.target.height ? "portrait" : "";
  }

  private ThubnailMouseEnterEvent(event: any): void {
    $(event.target).finish();
    $(event.target).animate({ height: "+=10%", width: "+=10%" }, 1000);
  }

  private ThubnailMouseLeaveEvent(event: any): void {
    $(event.target).finish();
    $(event.target).animate({ height: "-=10%", width: "-=10%" }, 1000);
  }

  private FullScreenImageLoadEvent(): void {
    this.imageIsLoaded = true;
  }

  private LoadFolder(folderPath: string): void {
    this.router.navigate([this.componentUrl], { queryParams: { FolderPath: folderPath } });
  }

  private ShowPreviousImage(): void {
    if (this.actualFullScreenImageIndex > 0) {
      this.actualFullScreenImageIndex--;
      this.SetImageUrlByIndex();
      this.imageIsLoaded = false;
    }
  }

  private ShowNextImage(): void {
    if (this.actualFullScreenImageIndex + 1 < this.imageItems.length) {
      this.actualFullScreenImageIndex++;
      this.SetImageUrlByIndex();
      this.imageIsLoaded = false;
    }
  }

  private SetImageUrlByIndex(): void {
    this.imageIsLoaded = true;
    this.fullScreenImgUrl = this.imageItems[this.actualFullScreenImageIndex].ImageUrl;
  }

  private DeleteImage(item: GalleryImageDto) {
    this.deleteDialog.Show("Naozaj chcete zmazať obrázok " + item.Name + "?", () => {
      this.galleryService.DeleteImage(this.actualFolderPath, item.Name + "." + item.Ext, (result) => {
        alert(result.Message);
        if (result.Result) {
          this.LoadGalleryItems();
        }
      });
    });
  }

  private DeleteFolder(item: GalleryFolderItem) {
    this.deleteDialog.Show("Naozaj chcete zmazať priečinok " + item.Name + "?", () => {
      this.galleryService.DeleteFolder(this.actualFolderPath, item.Name, (result) => {
        alert(result.Message);
        if (result.Result) {
          this.LoadGalleryItems();
        }
      });
    });
  }

  private RenameFolder(item: GalleryFolderItem) {
    this.addNewGalleryItemDialog.Edit(GalleryItemType.folder, this.actualFolderPath, item);
  }

  private AddNewImage() {
    this.addNewGalleryItemDialog.Create(GalleryItemType.image, this.actualFolderPath);
  }

  private AddImages() {
    this.addNewGalleryItemDialog.Create(GalleryItemType.images, this.actualFolderPath);
  }

  private AddNewFolder() {
    this.addNewGalleryItemDialog.Create(GalleryItemType.folder, this.actualFolderPath);
  }
}
