import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../DtoClasses/MenuDto';
import { WebMenuConfig, WebMenuDisplayMode } from '../web-menu/web-menu.component';

declare var $: any;

@Component({
  selector: 'web-menu-item',
  templateUrl: './web-menu-item.component.html',
  styleUrls: ['./web-menu-item.component.css']
})
export class WebMenuItemComponent implements OnInit {
  @Input() items: Array<MenuItem>;
  @Input() parentItem: MenuItem; 
  // menu configuration
  @Input() Config: WebMenuConfig;

  @Output() menuItemClick = new EventEmitter<MenuItem>();

  constructor() { }

  ngOnInit() {
  }

  private getUlClassName() {
    let className = this.parentItem != null
      ? this.Config.DropDownMenuClasses + " dropdown-menu"
      : this.Config.MainMenuClasses;
    return className;
  }

  private getLiClassName(item: MenuItem) {
    let className = item.HasParent() ? this.Config.DropDownMenuItemContainerClasses : this.Config.MainMenuItemContainerClasses;

    if (item.HasChildren() && item.HasParent()) {
      className += " dropdown-submenu";
    } else if (item.HasChildren()) {
      className += this.Config.DisplayMode == WebMenuDisplayMode.Horizontally ? " dropdown" : " dropright";
    }

    return className;
  }

  private getAClassName(item: MenuItem) {
    let className = this.parentItem == null
      ? this.Config.MainMenuItemClasses
      : this.Config.DropDownMenuItemClasses + " dropdown-item";

    if (item.HasChildren()) {
      className += " dropdown-toggle";
    }

    return className;
  }

  private OnMenuItemClick(menuItem: MenuItem) {
    this.menuItemClick.emit(menuItem);
    const fragment = menuItem.GetUrlFragment();

    if (fragment != null) {
      const element = document.querySelector("#" + fragment);
      if (element) { element.scrollIntoView(true); }
    }
  }
}
