export class MenuDto {
    public Id: number;
    public Name: string;
    public CreatedBy: number;
    public UserName: string;
}

export class MenuItemDto {
    public Id: number;
    public Name: string;
    public MenuId: number;
    public ParentMenuItemId: number;
    public Url: string;
    public CreatedBy: number;
    public UserName: string;
}

export class MenuItem {
    public Id: number;
    public Name: string;
    public ParentMenuItem: MenuItem;
    public MenuItems: Array<MenuItem>;
    public Dto: MenuItemDto;
    public ShowChildren: Boolean;
    public ShowControls: Boolean;

    constructor(dto: MenuItemDto) {
        this.Dto = dto;
        this.Id = dto.Id;
        this.Name = dto.Name;
        this.MenuItems = new Array<MenuItem>();
    }

    public IsGlobalUrl() {
        return this.Dto.Url && this.Dto.Url.indexOf("http") == 0;
    }

    public GetUrlWithoutFragment() {
        if (!this.Dto.Url) return null;

        return this.Dto.Url.split("#")[0];
    }

    public GetUrlFragment() {
        if (!this.Dto.Url) return null;
        
        var urlParts = this.Dto.Url.split("#");
        return urlParts.length == 1 ? null : this.Dto.Url.split("#")[1];
    }

    public HasParent() {
        return this.ParentMenuItem != null;
    }

    public HasChildren() {
        return this.MenuItems != null && this.MenuItems.length > 0;
    }
}

export class MenuItemsGenerator {
    public static GetMenuItems(menuItemsDto: Array<MenuItemDto>) {
        var menuItems = new Array<MenuItem>();
        var mainItemsDto = menuItemsDto.filter(item => item.ParentMenuItemId == null);
        mainItemsDto.forEach(dto => {
            var menuItem = new MenuItem(dto);
            menuItem.MenuItems = this.GetMenuItemChild(menuItem, menuItemsDto);
            menuItems.push(menuItem);

        });
        return menuItems;
    }

    private static GetMenuItemChild(parentMenuItem: MenuItem, menuItemsDto: Array<MenuItemDto>) {
        var menuItems = new Array<MenuItem>();
        var childItemsDto = menuItemsDto.filter(item => item.ParentMenuItemId == parentMenuItem.Id);
        childItemsDto.forEach(dto => {
            var menuItem = new MenuItem(dto);
            menuItem.ParentMenuItem = parentMenuItem;
            menuItem.MenuItems = this.GetMenuItemChild(menuItem, menuItemsDto);
            menuItems.push(menuItem);
        });
        return menuItems;
    }

    public static GetChildrenIds(menuItem: MenuItem) {
        const ids = new Array<number>();
        ids.push(menuItem.Id);
        if (menuItem.MenuItems != null && menuItem.MenuItems.length > 0) {
          menuItem.MenuItems.forEach((item) => {
            this.GetChildrenIds(item).forEach((id) => { ids.push(id); });
          });
        }
        return ids;
      }
}