import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItemDto, MenuItem, MenuItemsGenerator, MenuDto } from '../DtoClasses/MenuDto';
import { MenuService } from '../menu.service';
import { CmsDialogComponent } from '../cms-dialog/cms-dialog.component';
import { CmsEditMenuItemComponent } from '../cms-edit-menu-item/cms-edit-menu-item.component';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'cms-menu',
  templateUrl: './cms-menu.component.html',
  styleUrls: ['./cms-menu.component.css']
})
export class CmsMenuComponent implements OnInit {
  private menuId: number;
  private menuDto: MenuDto = new MenuDto();
  private insertMode: Boolean = false;
  private inProgress: Boolean = false;
  private items: Array<MenuItem> = new Array<MenuItem>();
  private itemForDelete: MenuItem;

  @ViewChild(CmsDialogComponent) deleteDialog;
  @ViewChild(CmsEditMenuItemComponent) editDialog;

  constructor(private menuService: MenuService, private userService: UserService, private route: ActivatedRoute) {
    const menuId = this.route.snapshot.paramMap.get('id');

    if (menuId == null || menuId == undefined) {
      this.insertMode = true;
    } else {
      this.menuId = Number(menuId);
      this.insertMode = false;
      this.inProgress = true;

      this.menuService.GetMenu(this.menuId, (menu) => {
        if (!menu) return;
        this.menuDto = menu;

        this.menuService.GetMenuItems(this.menuId, (items) => {
          this.inProgress = false;
          if (!items) return;
          this.items = MenuItemsGenerator.GetMenuItems(items);
        });
      });
    }
  }

  ngOnInit() {
  }

  private SaveMenu() {
    if (this.menuDto.Name.length == 0) {
      alert("Názov menu nie je zadaný.");
      return;
    }

    this.inProgress = true;

    if (this.insertMode) {
      this.menuService.InsertMenu(this.menuDto, (result) => {
        this.inProgress = false;
        if (!result) return;

        if (result.Result) {
          this.insertMode = false;
          this.menuId = Number.parseInt(result.InsertedId);
          this.menuService.GetMenu(this.menuId, (result) => {
            this.menuDto = result;
          });        
        } else {
          alert(result.Message);
        }
      });
    } else {
      this.menuService.UpdateMenu(this.menuDto, (result) => {
        this.inProgress = false;
        if (!result) return;
        alert(result.Message);
      });
    }
  }

  private AddMenuItem(event: any) {
    let menuItemDto = new MenuItemDto();
    menuItemDto.Name = event.name;
    menuItemDto.MenuId = this.menuId;
    menuItemDto.ParentMenuItemId = null;
    menuItemDto.Url = null;

    if (event.parent != null) {
      menuItemDto.ParentMenuItemId = event.parent.Id;
    }

    this.menuService.InsertMenuItem(menuItemDto, (result) => {
      if (result.Result) {
        menuItemDto.Id = Number.parseInt(result.InsertedId);
        menuItemDto.CreatedBy = this.userService.LoggedUser.Id;
        let newMenuItem = new MenuItem(menuItemDto);

        if (event.parent != null) {
          newMenuItem.ParentMenuItem = event.parent;
          event.parent.MenuItems.push(newMenuItem);
        } else {
          this.items.push(newMenuItem);
        }

        event.sender.ClearName();
      } else {
        alert(result.Message);
      }
    });
  }

  private DeleteMenuItem(event: any) {
    this.itemForDelete = event.item;
    this.deleteDialog.Show("Naozaj chcete zmazať položku " + event.item.Name + "?");
  }

  private EditMenuItem(item: MenuItem) {
    this.editDialog.EditMenuItem(item);
  }

  private DeleteDialogClicked(returnValue: boolean) {
    if (returnValue) {
      this.RemoveMenuItem(this.itemForDelete);
    }
  }

  private RemoveMenuItem(item: MenuItem) {
    this.menuService.DeleteMenuItem(item.Id, result => {
      if (!result) return;
      alert(result.Message);
      if (result.Result) {
        if (item.ParentMenuItem == null) {
          this.items.splice(this.items.indexOf(item), 1);
        } else {
          item.ParentMenuItem.MenuItems.splice(item.ParentMenuItem.MenuItems.indexOf(item), 1);
        }
      }
    });
  }
}
