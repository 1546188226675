import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

declare var $: any;

export class BaseService {
    private loadingCounter: number = 0;
    private http: HttpClient;

    constructor(http: HttpClient) { 
        this.http = http;
    }

    private showLoader() {
        this.loadingCounter++;

        if (!document.getElementById("simpleCmsLoader")) this.createLoderElements();

        document.getElementById("simpleCmsLoader").style.display = "block";
    }

    private hideLoader() {
        this.loadingCounter--;

        if (this.loadingCounter > 0) return;
        
        var loader = document.getElementById("simpleCmsLoader");

        if (loader) loader.style.display = "none";
    }

    private createLoderElements() {
        var body = document.getElementsByTagName("BODY")[0];
        var loaderContainer = document.createElement("div");
        loaderContainer.setAttribute("id", "simpleCmsLoader");
        loaderContainer.style.cssText = "position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 10001";
        var loaderImg = document.createElement("img");
        loaderImg.setAttribute("src", "./assets/gallery-images/loader.gif");
        loaderImg.style.cssText = "position: absolute; top: calc(50% - 2em); left: calc(50% - 2em); width: 4em; height: 4em; z-index: 10002";
        loaderContainer.appendChild(loaderImg);
        body.appendChild(loaderContainer); 
    }

    protected Get<T>(url: string, params: HttpParams, result: (output: T) => void) {
        this.showLoader();
        this.http.get<T>(url, { params }).subscribe(data => {
            this.success();
            result(data);
        }, error => {
            this.failed(error);
            result(null);
        });
    }

    protected Post<T>(url: string, formData: FormData, result: (output: T) => void) {
        this.showLoader();
        this.http.post<T>(url, formData).subscribe(data => {
            this.success();
            result(data);
        }, error => {
            this.failed(error);
            result(null);
        });
    }

    protected PostBody<T>(url: string, body: any, result: (output: T) => void) {
        this.showLoader();
        this.http.post<T>(url, body).subscribe(data => {
            this.success();
            result(data);
        }, error => {
            this.failed(error);
            result(null);
        });
    }

    private success() {
        this.hideLoader();
    }

    private failed(error: any) {
        this.hideLoader();
        if (error.status == 401) {
            window.location.href = "/login";
            return;
        }

        $.toaster('Chyba spojenia.', '', 'danger');
    }
}