import { Injectable } from '@angular/core';
import { MenuDto, MenuItemDto } from './DtoClasses/MenuDto';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestResult } from './DtoClasses/RequestResult';
import { Settings } from './Global';
import { BaseService } from './BaseService';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public GetMenus(result: (data: Array<MenuDto>) => void) {
    this.Get<Array<MenuDto>>(Settings.BaseApiUrl + "GetMenus.php", null, result);
  }

  public GetMenu(menuId: number, result: (data: MenuDto) => void) {
    let params = new HttpParams().set("MenuId", menuId.toString());
    this.Get<MenuDto>(Settings.BaseApiUrl + "GetMenu.php", params, result);
  }

  public InsertMenu(menu: MenuDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "InsertMenu.php", menu, result);
  }

  public UpdateMenu(menu: MenuDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "UpdateMenu.php", menu, result);
  }

  public DeleteMenu(menuId: number, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("MenuId", menuId.toString());
    this.Get<RequestResult>(Settings.BaseApiUrl + "DeleteMenu.php", params, result);
  }

  public GetMenuItems(menuId: number, result: (data: Array<MenuItemDto>) => void) {
    let params = new HttpParams().set("MenuId", menuId.toString());
    this.Get<Array<MenuItemDto>>(Settings.BaseApiUrl + "GetMenuItems.php", params, result);
  }

  public InsertMenuItem(menuItem: MenuItemDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "InsertMenuItem.php", menuItem, result);
  }

  public UpdateMenuItem(menuItem: MenuItemDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "UpdateMenuItem.php", menuItem, result);
  }

  public DeleteMenuItem(menuItemId: number, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("MenuItemId", menuItemId.toString());
    this.Get<RequestResult>(Settings.BaseApiUrl + "DeleteMenuItem.php", params, result);
  }
}
