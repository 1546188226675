export class UserTypeOptionsHelper {
    private static userTypeOptions = [{ name: "Administrátor", value: "1" }, { name: "Užívateľ", value: "2" }];

    public static GetUserTypeOptions() {
        return this.userTypeOptions;
    }

    public static GetUserTypeNameByValue(value: number) {
        return this.userTypeOptions.find(o => o.value == value.toString()).name;
    }
}