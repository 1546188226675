import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { GalleryItemDto } from '../gallery/GalleryDataClasses';

declare var $: any;

@Component({
  selector: 'cms-gallery-create-element',
  templateUrl: './cms-gallery-create-element.component.html',
  styleUrls: ['./cms-gallery-create-element.component.css']
})
export class CmsGalleryCreateElementComponent implements OnInit {
  private title = "Nový obrázok";
  private itemName: string;
  private inProgress: boolean = false;
  private imageFiles: FileList = null;
  private imageNames = "";
  private itemType: GalleryItemType = GalleryItemType.image;
  private folderPath: string;
  private editItem: GalleryItemDto = null;
  private uploadingProgress: string;

  @Output() itemInsertedEvent = new EventEmitter();

  constructor(private galleryService: GalleryService) { }

  ngOnInit() {
  }

  public Create(itemType: GalleryItemType, folderPath: string) {
    this.editItem = null;
    this.itemName = "";
    this.imageFiles = null;
    this.imageNames = "";
    this.itemType = itemType;
    this.folderPath = folderPath;

    switch (this.itemType) {
      case GalleryItemType.image:
        this.title = "Nový obrázok";
        break;

      case GalleryItemType.images:
        this.title = "Nové obrázoky";
        break;

      case GalleryItemType.folder:
        this.title = "Nový priečinok";
        break;
    }

    $('#createGalleryItemModalCenter').modal('show');
  }

  public Edit(itemType: GalleryItemType, folderPath: string, item: GalleryItemDto) {
    this.editItem = item;
    this.itemName = item.Name;
    this.itemType = itemType;
    this.folderPath = folderPath;
    this.title = this.itemType == GalleryItemType.image ? "Upraviť obrázok" : "Upraviť priečinok";
    $('#createGalleryItemModalCenter').modal('show');
  }

  private FileChange(event: any) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.imageFiles = fileList;
      this.imageNames = this.itemType == GalleryItemType.image ? this.imageFiles[0].name : "Počet obrázkov: " + this.imageFiles.length;
    }
  }

  private UploadFile() {
    if (this.imageFiles) {
      let imageName = null;

      if (this.imageFiles.length == 1 && this.itemName != null && this.itemName != "") {
        imageName = this.itemName + "." + this.imageFiles[0].name.split('.').pop();
      }

      this.inProgress = true;
      let progress = 0;

      for (var i = 0; i < this.imageFiles.length; i++) {
        this.galleryService.uploadImage(this.imageFiles[i], this.folderPath, imageName, (result) => {
          progress++;
          this.uploadingProgress = "Uploading: " + progress + "/" + this.imageFiles.length;
          
          if (progress >= this.imageFiles.length) {
            this.inProgress = false;
            this.itemInsertedEvent.emit();
            $('#createGalleryItemModalCenter').modal('hide');
          }
        });
      }
    }
  }

  private CreateDirectory() {
    if (this.itemName == "") {
      alert("Názov priečinka nie je vyplnený.");
      return;
    }

    this.galleryService.CreateFolder(this.folderPath, this.itemName, (result) => {
      if (!result.Result) {
        alert("Priečinok sa nepodarilo vytvoriť.");
        return;
      }

      alert(result.Message);
      $('#createGalleryItemModalCenter').modal('hide');
      this.itemInsertedEvent.emit();
    });
  }

  private UpdateItem() {
    this.galleryService.RenameFolder(this.folderPath, this.editItem.Name, this.itemName, (result) => {
      this.itemInsertedEvent.emit();
      $('#createGalleryItemModalCenter').modal('hide');
    });
  }

  private Close() {
    $('#createGalleryItemModalCenter').modal('hide');
  }

}

export enum GalleryItemType {
  image = 0,
  folder = 1,
  images = 2
}
