export class GalleryItemDto {
    public Name: string;
    public Title: string;
    public DateTime: Date;
}

export class GalleryImageDto extends GalleryItemDto {
    public ThumbnailUrl: string;
    public ImageUrl: string;
    public Ext: string;
}

export class GalleryFolderDto extends GalleryItemDto {
    public ImagesUrls: Array<string>;
    public FolderPath: string;
}

export class GalleryItemsDto {
    public Folders: Array<GalleryFolderDto>;
    public Images: Array<GalleryImageDto>;
}