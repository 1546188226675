import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { MenuItemsGenerator, MenuItem } from '../DtoClasses/MenuDto';

@Component({
  selector: 'web-menu',
  templateUrl: './web-menu.component.html',
  styleUrls: ['./web-menu.component.css']
})
export class WebMenuComponent implements OnInit {
  private MenuName: string = "";
  private menuItems: Array<MenuItem>;

  @Input() MenuId: number;
  // menu configuration
  @Input() Config: WebMenuConfig;

  constructor(private menuSevice: MenuService) {
  }

  ngOnInit() {
    this.menuSevice.GetMenu(this.MenuId, (result) => {
      if (!result) return;
      this.MenuName = result.Name;
    });

    this.menuSevice.GetMenuItems(this.MenuId, (result) => {
      if (!result) return;
      this.menuItems = MenuItemsGenerator.GetMenuItems(result);
    });
  }

  private OnMenuItemClick(menuItem: MenuItem) {
    // This code hides dropdown menus for mobile devices, it works good only for pc web browsers
    //this.menuItems.forEach(item => item.ShowChildren = false);
  }
}

export class WebMenuConfig {
  public DisplayMode: WebMenuDisplayMode = 0;
  public ShowMenuName: boolean = false;

  // Name label classes
  public NameLabelClasses: string;

  // first <ul>
  public MainMenuClasses: string;

  //first <li>
  public MainMenuItemContainerClasses: string;

  //first <a>
  public MainMenuItemClasses: string;

  // child <ul>
  public DropDownMenuClasses: string;

  // child <li>
  public DropDownMenuItemContainerClasses: string;

  // child <a>
  public DropDownMenuItemClasses: string;
}

export enum WebMenuDisplayMode {
  Horizontally = 0,
  Vertically = 1
}
