import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private username: string = "";
  private password: string = "";
  private inProgress: boolean = false;

  constructor(private route: Router, private userService: UserService) { }

  ngOnInit() {
  }

  login() {
    this.inProgress = true;
    this.userService.Login(this.username, this.password, (result) => {
      if (result) {
        this.route.navigate(['cms']);
      } else {
        alert("Login failed!");
      }
      this.inProgress = false;
    });
  }
}
