import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../menu.service';
import { MenuDto } from '../DtoClasses/MenuDto';
import { Router } from '@angular/router';
import { CmsDialogComponent } from '../cms-dialog/cms-dialog.component';

@Component({
  selector: 'cms-menus',
  templateUrl: './cms-menus.component.html',
  styleUrls: ['./cms-menus.component.css']
})
export class CmsMenusComponent implements OnInit {
  private activeRowId: string = "";
  private menus: Array<MenuDto>;
  private editMenu: MenuDto;

  @ViewChild(CmsDialogComponent) deleteDialog;

  constructor(private menuService: MenuService, private router: Router) { }

  ngOnInit() {
    this.Refresh();
  }

  private Refresh() {
    this.menuService.GetMenus((result) => {
      this.menus = result;
    });
  }

  private EditMenu(menuId: string) {
    this.router.navigate(['cms/menu/', menuId]);
  }

  private DeleteMenu(menuId: number) {
    this.editMenu = this.menus.find(p => p.Id == menuId);
    this.deleteDialog.Show("Naozaj chcete zmazať menu " + this.editMenu.Name + "?");
  }

  private OnClicked(returnValue: boolean) {
    if (returnValue) {
      this.menuService.DeleteMenu(this.editMenu.Id, (result) => {
        if (!result) return;
        if (result.Result) {
          this.Refresh();
        }
        alert(result.Message);
      });
    }
  }
}
