import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'cms-main',
  templateUrl: './cms-main.component.html',
  styleUrls: ['./cms-main.component.css']
})
export class CmsMainComponent implements OnInit {
  private activeMenu: string = "cms";

  @ViewChild(ChangePasswordComponent) changePasswordDialog;

  constructor(private route: Router, private userService: UserService) {
    if (!this.userService.IsLogged()) {
      this.route.navigate(['login']);
    }
  }

  ngOnInit() {
  }

  Logout() {
    this.userService.Logout();
    this.route.navigate(['login']);
  }

  private ChangePasswordClick() {
    this.changePasswordDialog.Show();
  }
}
