import { GalleryFolderDto } from "./GalleryDataClasses";

export class GalleryFolderItem extends GalleryFolderDto {
    public FolderIndex: number;
    public AnimationImageIndex: number = 0;

    constructor(folderDto: GalleryFolderDto) {
        super();
        this.Name = folderDto.Name;
        this.Title = folderDto.Title;
        this.ImagesUrls = folderDto.ImagesUrls;
        this.FolderPath = folderDto.FolderPath;
        this.DateTime = folderDto.DateTime;
    }
}