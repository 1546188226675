import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestResult } from './DtoClasses/RequestResult';
import { PageDto } from './DtoClasses/PageDto';
import { Settings } from './Global';
import { BaseService } from './BaseService';

@Injectable({
  providedIn: 'root'
})
export class PageService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public GetPages(result: (data: Array<PageDto>) => void) {
    this.Get<Array<PageDto>>(Settings.BaseApiUrl + "GetPages.php", null, result);
  }

  public GetPage(pageId: string, result: (data: PageDto) => void) {
    let params = new HttpParams().set("PageId", pageId)
    this.Get<PageDto>(Settings.BaseApiUrl + "GetPage.php", params, result);
  }

  public InsertPage(page: PageDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "InsertPage.php", page, result);
  }

  public UpdatePage(page: PageDto, result: (data: RequestResult) => void) {
    this.PostBody<RequestResult>(Settings.BaseApiUrl + "UpdatePage.php", page, result);
  }

  public DeletePage(pageId: string, result: (data: RequestResult) => void) {
    let params = new HttpParams().set("PageId", pageId)
    this.Get<RequestResult>(Settings.BaseApiUrl + "DeletePage.php", params, result);
  }

  public DownloadPage(pageId: string) {
    window.location.href = Settings.BaseApiUrl + 'DownloadPage.php?PageId=' + pageId;
  }
}
