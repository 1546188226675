import { Component, OnInit, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'cms-list-dialog',
  templateUrl: './cms-list-dialog.component.html',
  styleUrls: ['./cms-list-dialog.component.css']
})
export class CmsListDialogComponent implements OnInit {
  private selectedItem: ListItem = null;
  private items: Array<any> = new Array<any>();

  @Output() itemSelectedEvent = new EventEmitter<ListItem>();

  constructor() { }

  ngOnInit() {
  }

  private Close() {
    $("#listDialogModalCenter").modal("hide");
  }

  public Show(items: Array<any>) {
    this.items = items;
    $('#listDialogModalCenter').modal('show');
  }

  public GetSelectedItem() {
    return this.selectedItem;
  }

  private SelectItem() {
    $("#listDialogModalCenter").modal("hide");
    this.itemSelectedEvent.emit(this.selectedItem);
  }
}

export class ListItem {
  public Item: any;
  public Name: string;

  constructor(name: string, item: any) {
    this.Name = name;
    this.Item = item;
  }
}
