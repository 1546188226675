import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../DtoClasses/MenuDto';

@Component({
  selector: 'cms-menu-item',
  templateUrl: './cms-menu-item.component.html',
  styleUrls: ['./cms-menu-item.component.css']
})
export class CmsMenuItemComponent implements OnInit {
  private name: string;

  @Input() parentItem: MenuItem;
  @Input() items: Array<MenuItem>;

  @Output() AddMenuItemEvent = new EventEmitter<{ sender: CmsMenuItemComponent, parent: MenuItem, name: string }>();
  @Output() DeleteMenuItemEvent = new EventEmitter<{ sender: CmsMenuItemComponent, item: MenuItem }>();
  @Output() EditMenuItemEvent = new EventEmitter<MenuItem>();

  constructor() { }

  ngOnInit() {
  }

  private RaiseAddMenuItemEvent(event: any) {
    this.AddMenuItemEvent.emit(event);
  }

  private RaiseDeleteMenuItemEvent(event: any) {
    this.DeleteMenuItemEvent.emit(event);
  }

  private RaiseEditMenuItemEvent(event: any) {
    this.EditMenuItemEvent.emit(event);
  }

  private AddMenuItem() {
    this.AddMenuItemEvent.emit({ sender: this, parent: this.parentItem, name: this.name });
  }

  private DeleteMenuItem(item: MenuItem) {
    this.DeleteMenuItemEvent.emit({ sender: this, item: item });
  }

  private EditMenuItem(item: MenuItem) {
    this.EditMenuItemEvent.emit(item);
  }

  public ClearName() {
    this.name = "";
  }
}
